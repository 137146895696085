import React from 'react';
// import Header from "./Header";
// import Footer from "./Footer";
// import Breadcrumb from "./Breadcrumb";

export default function Partners() {
    var BreadCrumbTitle = "Companies and Partners";
    document.title = BreadCrumbTitle;
    return (
        <>
            <div id="page">
                <nav className="gtco-nav" role="navigation" style={{height: "90px"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-2 col-xs-12">
                                <div id="gtco-logo" style={{height: "35px"}}><a href="/"><img
                                    src="template/images/logo.png"/> </a></div>
                            </div>
                            <div className="col-xs-10 text-right menu-1 main-nav"></div>
                        </div>
                    </div>
                </nav>

                <section id="gtco-our-team" data-section="about">
                    <div className="container">
                        <div className="row row-pb-md">
                            <div className="col-md-8 col-md-offset-2 heading animate-box" data-animate-effect="fadeIn">
                                <h1 style={{"color": "black", "alignContent": "center"}}>Companies and Partners</h1>
                                <p style={{"color": "black", "alignContent": "center"}}> Companies included, but not
                                    limited
                                    to: Aetna, Accuquote, AHCP, AHIA, AIP, Allegiant Group,Alpine Digital Group, Allied,
                                    American Benefit Services , American Continental Insurance, American Family
                                    Insurance, American Health Brokerage, American Health Underwriters, American
                                    Republic, American Republic Insurance, Amerigroup, Amerigroup, Amerilife Marketing
                                    Group, Ameriquote, AON, Art Jetter &amp; Co, Assurant, Assured Life, AvMed Inc., AXA
                                    Advisors, Bankers Life Insurance, Benefit Mall, Cambia, CareMore, CareSource, Cars
                                    Insurance, CDPHP, Centene, Cege Media, Cigna Health Group, Combined, Community Care,
                                    Complete Senior Benefits, ConnectiCare, Continental Life, Country Insurance,
                                    Coventry Health Care, Coverage One Insurance, DBA “Connect Insurance Brands”,
                                    E-Telequote, eHealth, Elderplan, EmblemHealth, Fallon Health, Family Life, Fidelis
                                    Care, First Financial, Forefront Health Insurance Solutions, Forethought, Geisinger
                                    Insurance, Genworth, Gerber Life, GHI, GMAC, Golden Rule, GoMedigap.com, Government
                                    Personnel Mutual Life, Guarantee Trust Life, GuideStar Marketing Group LLC,
                                    Guide2Insure, HAP Health Alliance, Harvard Pilgrim Health Care, Health Care Service
                                    Corp., Health Choice One, Health Insurance for Everyone, Health Markets, Health Net,
                                    Health Now New York Inc., Health Partners of Philadelphia , Health Refrom Team ,
                                    HealthPartners, HealthPlanOne, HealthPlus of Michigan, HealthSpring, Helios Energy
                                    Partners, Highmark, Hola Doctor, Humana, Errands Services Pvt. Ltd, iHealth Brokers,
                                    InsideResponse, Insphere, IntegratedBenefits, Inter Valley Health Plan, JLS, Kaiser
                                    Permanenente, KBM Group: Health Services, Keystone, LifeLine Direct Insurance
                                    Services , LifeWise Health Plan of Oregon, Longevity Alliance, Legal &amp; General
                                    America, Loyal American (GIGNA Supplement), MedicareSolutions, MediGap Direct,
                                    MercyCare, MetLife, Molina, Mutual of Omaha, MVP Health Care, MyExclusiveQuotes.com,
                                    National Family Assurance Group, Network Health, New Era, Noridian Mutual Insurance
                                    Company, Optimum HealthCare, Physicians Mutual, Physicians United Plan, Potesma
                                    Marketing Group, Precise Leads, Preferred Care Partners, Presbyterian Health Plan,
                                    Priority Health, Priority Health, Providence Health Plan, Puritan Health, QuoteWhiz,
                                    Regal, Regence, SCAN Health Plan, SCMS, Scott and White, Selectmypolicy.com,
                                    SelectQuote, Senior Health Direct, Senior Market Partners, Senior Market Sales,
                                    Sentinel, SolidQuote LLC Spring Venture Group, Stability Life, Standard Life, State
                                    Mutual, Sterling, SummaCare, Superior Insurance, TexanPlus, Touchstone,
                                    Transamerica, TZ Insurance Solutions LLC, Tufts, Ucare, Unicare, United American,
                                    United Health Care, United Medicare Advisors, United of Omaha, Universal American,
                                    UPMC Health Plan, Vasa North Atlantic, Velapoint , VelaPoint Insurance, Viva Health,
                                    Wellcare, WellPoint, Windsor, North Star Insurance Advisors, Premier Producers Group
                                    and Senior Benefits.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <footer id="gtco-footer" role="contentinfo">
                    <center>
                        <footer style={{backgroundColor: "black"}}>All Rights Reserved | 2019.</footer>
                    </center>
                </footer>
            </div>
            <div className="gototop js-top">
                <a href="#" className="js-gotop"><i className="icon-arrow-up"></i></a>
            </div>
        </>
    )
}
