import React from 'react';
// import Header from "./Header";
// import Footer from "./Footer";
// import Breadcrumb from "./Breadcrumb";

export default function PrivacyPolicy() {
    var BreadCrumbTitle = "Privacy Policy";
    document.title = BreadCrumbTitle;
    return (
        <>
            <div id="page">
                <nav className="gtco-nav" role="navigation" style={{height: "90px"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-2 col-xs-12">
                                <div id="gtco-logo" style={{height: "35px"}}><a href="/"><img
                                    src="template/images/logo.png"/> </a></div>
                            </div>
                            <div className="col-xs-10 text-right menu-1 main-nav"></div>
                        </div>
                    </div>
                </nav>
                <section id="gtco-hero" className="gtco-cover" style={{backgroundImage: "url(images/img_bg_4.jpg)"}}
                         data-section="home" data-stellar-background-ratio="0.5">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-md-offset-0 ">
                                <div className="col-md-12 col-md-offset-0 ">

                                    <div className="display-t">
                                        <div className="display-tc">
                                            <center><h1 style={{color: "#CE5109"}}>PRIVACY POLICY</h1>
                                                <p style={{color: "white"}}>(a) comply with all Federal Trade
                                                    Commission guidelines and any other applicable laws, rules, and
                                                    regulations
                                                    with respect to personally identifiable information and online
                                                    privacy,
                                                    including all applicable laws, <br/>rules and regulations with
                                                    respect to the online privacy of minors;<br/>(b) identify the nature
                                                    and scope
                                                    of the collection and use of information gathered by you;
                                                    and <br/>(c) offer users the ability to opt-out of collection and
                                                    use of personal data.</p></center>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer id="gtco-footer" role="contentinfo">
                    <center>
                        <footer style={{backgroundColor: "black"}}>All Rights Reserved | 2019. <a
                            href="/privacy">PRIVACY POLICY</a></footer>
                    </center>
                </footer>
            </div>

            <div className="gototop js-top">
                <a href="#" className="js-gotop"><i className="icon-arrow-up"></i></a>
            </div>
        </>
    )
}
