import {useState, useRef} from 'react';
import axios from "axios";

export default function Home() {
    const [name, setName] = useState('');
    const [lname, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [requesttype, setRequestType] = useState('');
    const [message, setMessage] = useState('');
    const formData = ({name, phone, email, subject, message})
    const [formResponse, setFormResponse] = useState('');
    const lead_idRef = useRef(null);


    const handleSubmit = async () => {
        const lead_id = lead_idRef.current.value;
        if (lead_id == '') {
            //handleSubmit();
            return false;
        }

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            "X-Requested-With": "*",
            "Access-Control-Allow-Methods": "OPTIONS,POST",
            "Access-Control-Allow-Credentials": true,
        };

        const data = {
            "host": "feinsured",
            "first_name": name,
            "last_name": lname,
            "email": email,
            "phone": phone,
            "address": '',
            "state": state,
            "zipcode": zipcode,
            "dob": "",
            // "lead_id": document.getElementById('leadid_token').value,
            "lead_id": lead_id,
            "ip_address": '',
            "remarks": "Request Type: " + requesttype + ". " + message
        };
        console.log("Form DATA: " + JSON.stringify(data));

        const url = 'https://feinsured.digitalwim.com/process_api.php';
        //const url = 'http://localhost/process_api.php';

        const result = await axios.post(url, data, {
            headers: headers
        });

        console.log(result.data.status);
        if (result.data.status == 'fail') {
            document.getElementById("ajaxResponse").innerHTML = '<div class="alert alert-danger">' + result.data.message + '</div>';
        }
        if (result.data.status == 'success') {
            document.getElementById("ajaxResponse").innerHTML = '<div class="alert alert-success">' + result.data.message + '</div>';
            const timeout = setTimeout(() => {
                window.location.replace('/');
            }, 2000);
        }
    };

    (function () {
        var s = document.createElement('script');
        s.id = 'LeadiDscript_campaign';
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//create.lidstatic.com/campaign/5ef1b70c-646e-32a2-0b10-8a11ea66b607.js?snippet_version=2&f=reset';
        var LeadiDscript = document.getElementById('LeadiDscript');
        LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
    })();


    return (
        <>
            <div id="page">
                <nav className="gtco-nav" role="navigation">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-2 col-xs-12">
                                <div id="gtco-logo" style={{"height": "35px"}}><a href="/"><img
                                    src="template/images/logo.png"/> </a></div>
                            </div>
                            <div className="col-xs-10 text-right menu-1 main-nav">
                                <ul>
                                    <li className="active"><a href="#" data-nav-section="home">Home</a></li>
                                    <li><a href="#" data-nav-section="about">About</a></li>
                                    <li><a href="#" data-nav-section="Why_Choose_Us">WHY FINAL EXPENSE</a></li>
                                    <li className="btn-cta">
                                        <a href="#" data-nav-section="contact"><span>+1(210)714 4341</span></a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>

                <section id="gtco-hero" className="gtco-cover"
                         style={{backgroundImage: "url(template/images/img_bg_4.jpg)"}}
                         data-section="home" data-stellar-background-ratio="0.5">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-md-offset-0 ">
                                <div className="col-md-5 col-md-offset-0 ">
                                    <div className="" style={{"marginTop": "20%"}}>
                                        <div className="" style={{"color": "white", "fontSize": "25px"}}><br/>
                                            <h1 className="animate-box" data-animate-effect="fadeIn"
                                                style={{"color": "white", "fontSize": "60px"}}>
                                                <b>FINAL EXPENSE LIFE INSURANCE</b>
                                            </h1>
                                            <ul>
                                                <li>EASY PROCESS</li>
                                                <li>NO HEALTH EXAMS</li>
                                                <li>GUARANTEED ACCEPTANCE</li>
                                            </ul>
                                            <p> Contact us for an no-obligation quote!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 col-md-offset-0 ">

                                    <div className="display-t">
                                        <div className="display-tc">
                                            <center><h1 className="animate-box" data-animate-effect="fadeIn">Get An
                                                No-Obligation Quote!</h1></center>
                                            <form onSubmit={handleSubmit} action="#" method="post"
                                                  className="contact-form" name='contact-form' id='contact-form'>
                                                <input ref={lead_idRef} id="leadid_token" name="universal_leadid"
                                                       type="hidden" value=""/>
                                                <div className="col-md-12 ">
                                                    <div className="col-md-6 hide d-none">
                                                        <div className="form-group">
                                                            <input type="text" name="name" id="name"
                                                                   className="form-control  "
                                                                   placeholder="COMPLETE NAME" value={name}
                                                                   onChange={e => setName(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 hide d-none">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" name="email"
                                                                   id="email" value={email}
                                                                   onChange={e => setEmail(e.target.value)}
                                                                   placeholder="Email Address"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 ">
                                                        <div className="form-group">
                                                            <label style={{"color": "white"}}>Contact Number</label>
                                                            <input type="number" className="form-control capture_number"
                                                                   name="phone" id="phone" required="" value={phone}
                                                                   onChange={e => setPhone(e.target.value)}
                                                                   placeholder="Phone Number"/>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 hide d-none">
                                                        <div className="form-group">
                                                            <input type="text" name="state" id="state"
                                                                   placeholder="State" value={state}
                                                                   onChange={e => setState(e.target.value)}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 hide d-none">
                                                        <div className="form-group">
                                                            <textarea type="textarea" cols="40" rows="6" id="message"
                                                                      name="message" className="form-control  "
                                                                      placeholder="MESSAGE" value=""> </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 ">
                                                        <div className="form-group">
                                                            <label htmlFor="leadid_tcpa_disclosure"> <input
                                                                type="hidden" name="leadid_tcpa_disclosure"
                                                                id="leadid_tcpa_disclosure"
                                                                value="By clicking the Submit button I agree to this website s privacy policy and provide my signature expressly consenting up to three insurance companies or their agents or partner companies to contact me at the email address landline or wireless phone numbers that I provided even if it is listed on a government Do-Not-Call registry with insurance quotes or to market their products or services I agree to receive communications via live automated telephone dialing system prerecorded messages or text messages and acknowledge that consent is not a condition to purchase goods or services"/>
                                                            </label>
                                                            <p className="tcp"
                                                               style={{"fontSize": "12px", "color": "white"}}>
                                                                <label><input type="hidden"
                                                                              id="leadid_tcpa_disclosure"/>[By clicking
                                                                    the "Send" button, I consent to receive e-mails,
                                                                    telephone calls, text messages and/or artificial or
                                                                    pre-recorded messages from SIA insurance, its
                                                                    affiliates and/or <a href="/partners"
                                                                                         style={{"color": "lightgreen"}}>
                                                                        third-party partners</a> regarding life insurance products
                                                                    and services at the e-mail address and telephone
                                                                    number provided above, including my wireless number
                                                                    (if provided), using an automated telephone dialing
                                                                    system, even if my telephone number is on a
                                                                    government Do-Not-Call registry. I understand this
                                                                    consent is not a condition to purchase goods or
                                                                    services and that I may revoke this consent at any
                                                                    time. I agree to this website’s
                                                                    <a href="/privacy" style={{"color": "lightgreen"}}>
                                                                        Privacy Policy</a>.]</label></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group text-center">
                                                    <input type="button" name="submit" id="submit"
                                                           onClick={handleSubmit}
                                                           value="Send" className="btn btn-primary btn-lg"/>
                                                </div>
                                                <div className="col-sm-12" id="ajaxResponse"></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="gtco-our-team" data-section="about">
                    <div className="container">
                        <div className="row row-pb-md">
                            <div className="col-md-8 col-md-offset-2 heading animate-box" data-animate-effect="fadeIn">
                                <h1>About Us</h1>
                                <p className="sub">FE Insured was created with a simple mission: To be an easy,
                                    intuitive, trustworthy source for the insurance older Americans need most. Since
                                    2014, FE Insured has specialized in providing affordable, high quality life
                                    insurance, burial assistance and Medicare supplement products for our customers. We
                                    work with a wide variety of agencies and carriers to find the plan that will work
                                    best for you and your family. It is never too late to get the insurance you need
                                    most. Contact us today to speak to a licensed insurance agent and receive a free,
                                    no-obligation quote.</p>
                                <p className="subtle-text animate-box" data-animate-effect="fadeIn">Welcome</p>
                            </div>
                        </div>

                        <div className="row team-item gtco-team" data-section="Why_Choose_Us">
                            <div className="col-md-6 col-md-pull-1 animate-box" data-animate-effect="fadeInLeft">
                                <div className="img-shadow">
                                    <img src="template/images/guaranteed-life-insurance.jpg" className="img-responsive"
                                         alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
                                <h2>How Can I Get a Quote?</h2>
                                <h2 className="heading-colored"></h2>
                                <p>FE Insured has made getting an insurance quote easier than ever before. Gone are the
                                    days when you had to go to a doctor or drive to an insurance agency to get an
                                    insurance policy. Just putting your information in the form above is all it takes to
                                    get contacted by one of a licensed insurance agent.</p>
                                <ul>
                                    <li>One of Our Licensed Insurant Agents</li>
                                    <li>NO Medical Exams</li>
                                    <li>Guaranteed Acceptance</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row team-item gtco-team-reverse">
                            <div className="col-md-6 col-md-push-7 animate-box" data-animate-effect="fadeInRight">
                                <div className="img-shadow">
                                    <img src="template/images/Burial-Insurance-Involves-Lot-of-Health-Questions.jpg"
                                         className="img-responsive" alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6  col-md-pull-6 animate-box" data-animate-effect="fadeInRight">
                                <h2>Why Do I Need Final Expense Life Insurance?</h2>
                                <h2 className="heading-colored"></h2>
                                <p> Beyond the emotional toll, the death of a loved one can also be expensive for the
                                    family. A funeral can cost more than $9000, which is a significant expense for many
                                    people. Millions of Americans rely on final expense life insurance policies to
                                    protect their families and loved ones. You can get a affordable final expense plan
                                    today, regardless of health and pre-existing conditions. With less than a 1 hour
                                    phone call, you can give yourself and your family peace of mind.</p>
                            </div>
                        </div>
                    </div>
                </section>


                <footer id="gtco-footer" role="contentinfo">
                    <center>
                        <footer style={{"backgroundColor": "black"}}>All Rights Reserved | 2019.
                            <a href="/privacy">PRIVACY POLICY</a></footer>
                    </center>
                </footer>
            </div>

            <div class="gototop js-top">
                <a href="#" class="js-gotop"><i class="icon-arrow-up"></i></a>
            </div>
        </>
    )
}
